import React from 'react';
import { graphql } from 'gatsby';
import '../fragments';
import { ColorObject } from '../graphql-fragments/ColorObject';
import ArticleModule from '../components/modules/ArticleModule';
import { LocalizedSEO } from '../fragments';
import PageSEO from '../components/PageSEO';
import LayoutContainer from '../components/ui/LayoutContainer';
import Hero from '../components/ui/Hero';
import Button from '../components/ui/Button';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { Helmet } from 'react-helmet';
import { getPortableTextAsString, getReferenceUrl, urlJoin } from '../utils/utils';
import logo from '../images/logo-final.svg';

import * as styles from './ArticlePage.module.scss';
import { RawPortableText } from '../types';
import { SITE_BASE_URL } from '../constants';

export const query = graphql`
  query GetArticlePageById($id: String!) {
    sanityArticle(id: { eq: $id }) {
      _createdAt
      modificationDate
      title
      slug {
        current
      }
      forceUrlEqualSlug
      introText
      image {
        asset {
          url
        }
        alt
      }
      withHeroForm
      heroColor {
        ...ColorObject
      }
      category {
        title
        slug {
          current
        }
      }
      _rawBody(resolveReferences: { maxDepth: 6 })
      seo {
        ...SEO
      }
    }
  }
`;

interface ArticlePageProps {
  data: {
    sanityArticle: {
      _createdAt: string;
      modificationDate?: string;
      title: string;
      slug: {
        current: string;
      };
      forceUrlEqualSlug?: boolean;
      introText?: string;
      image: {
        asset: {
          url: string;
        };
        alt?: string;
      };
      withHeroForm?: boolean;
      heroColor: ColorObject;
      category: {
        title: string;
        slug: {
          current: string;
        };
      };
      _rawBody: RawPortableText;
      seo?: LocalizedSEO;
    };
  };
}

const ArticlePage = ({ data }: ArticlePageProps): React.ReactElement => {
  const {
    _createdAt,
    modificationDate,
    title,
    slug,
    forceUrlEqualSlug,
    introText,
    image,
    withHeroForm,
    heroColor,
    category,
    _rawBody,
    seo,
  } = data.sanityArticle;
  const bodyAsString = getPortableTextAsString(_rawBody);

  const bodyFirstParagraph = bodyAsString.split('\n')[0];
  const initialArticleContent =
    bodyAsString.length > 110 ? bodyAsString.substring(0, 108) + '...' : bodyAsString;

  return (
    <>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify({
            '@context': 'http://schema.org',
            '@type': 'Article',
            name: title,
            mainEntityOfPage: 'Golden Visa Community',
            headline: bodyFirstParagraph,
            author: {
              '@type': 'Organization',
              name: 'Golden Visa Community',
              url: SITE_BASE_URL,
            },
            datePublished: _createdAt,
            dateModified: modificationDate ? new Date(modificationDate).toISOString() : _createdAt,
            image: image.asset.url,
            articleSection: category.title,
            articleBody: bodyAsString,
            url: urlJoin(
              SITE_BASE_URL,
              getReferenceUrl('SanityArticle', slug.current, forceUrlEqualSlug),
            ),
            publisher: {
              '@type': 'Organization',
              name: 'Golden Visa Community',
              logo: {
                '@type': 'ImageObject',
                url: SITE_BASE_URL + logo,
              },
            },
          })}
        </script>
      </Helmet>
      <LayoutContainer currentPage={slug.current} withHeroForm={withHeroForm}>
        <PageSEO
          defaultTitle={title}
          defaultImageUrl={image.asset.url}
          defaultDescription={initialArticleContent}
          pageSEO={seo}
        />
        <Hero
          isTextBelowTitle
          heroType="genericPage"
          title={title}
          text={introText}
          color={heroColor?.title}
          images={[image]}
          withForm={withHeroForm}
          withTableOfContents
          tableOfContentsPortableText={_rawBody}
        >
          <div className={styles.buttonsContainer}>
            <AnchorLink href={'#shareSection'} className={styles.fakeAnchor} offset="200">
              <Button className={styles.button} leftIcon={'share'}>
                Share
              </Button>
            </AnchorLink>
          </div>
        </Hero>
        <ArticleModule
          _rawContent={_rawBody}
          previousModuleBgColor="white"
          showShareSection
          withHeroForm={withHeroForm}
        />
      </LayoutContainer>
    </>
  );
};

export default ArticlePage;
